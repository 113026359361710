export const understandTheCapitalStack = {
  order: 1,
  name: "Understand the capital stack",
  keywords: "equity debt nested mezz sale financing recap partial interest deal hub",
  subcategory: "Investments",
  markdown: `# Understand the capital stack

  Tower Hunt uses {% inlineRouterLink articleId="the-role-of-investments" %}investments{% /inlineRouterLink %} to display an asset's *capital stack*, which is an illustration of the various layers of funding ordered by their rights to the cash flow. The capital stack is a useful concept in real estate because it can help you identify all of the players and how they are related. Additionally, you can use the capital stack to {% inlineRouterLink articleId="create-an-investment" %}create investments{% /inlineRouterLink %} that are context-aware. Read on to learn more.

  {% callout type="tip" %}
  **Tip:** In Tower Hunt, the capital stack is an is an interactive tool that helps you discover information and add new data. Be sure to check out our {% inlineRouterLink articleId="capital-stack-interactions" %}list of capital stack interactions{% /inlineRouterLink %}.
  {% /callout %}

  ## What it means

  Within "capital stack", the word *stack* has a visual meaning: each layer of capital is "stacked" vertically in the illustration, much like a vertical bar chart. A layer's placement within the stack corresponds to its "rank" in terms of receiving cash flow: the bottom-most layer is first to be paid and the top-most layer is last. Therefore, the relative repayment risk of each layer works in reverse: the bottom-most layer bears the least risk and the top-most layer the most.

  ## Types of capital

  Tower Hunt tracks the two most common types of capital: equity and debt. The capital stack uses color to indicate a layer's capital type:

  - *Common* equity is yellow.
  - *Preferred* equity is orange, and it appears inside of the equity layer of the stack. Preferred equity involves different levels of risk and control than common equity, which is why it is useful to identify the equity type.
  - The senior-most debt layer is dark blue, with each subordinate layer having a lighter shade of blue.

  ![Capital stack layers screenshot](https://assets.towerhunt.com/site/capitalStackLayers0.png)

  ## How it looks

  One of the best ways to understand the capital stack is by seeing it. In Tower Hunt, there are two places where the capital stack is displayed: the {% inlineRouterLink articleId="details-tab" %}Details tab{% /inlineRouterLink %} for property rights, and the {% inlineRouterLink articleId="deal-hub" %}Deal Hub tab{% /inlineRouterLink %}.

  {% callout type="tip" %}
  **Tip:** Looking for the different types of investments in Tower Hunt, as well as how they appear in the capital stack? We put together an {% inlineRouterLink articleId="investment-types" %}illustrated list of investment types{% /inlineRouterLink %}.
  {% /callout %}

  ### Details tab

  The {% inlineAppIcon iconName="capitalStack" /%}**Capital Stack section** of the {% inlineRouterLink articleId="details-tab" %}Details tab{% /inlineRouterLink %} (available {% inlineRouterLink articleId="select-a-property-diagram-part" %}when a property right is selected{% /inlineRouterLink %}) displays the capital stack as of the date in {% inlineRouterLink articleId="timeline" %}the timeline{% /inlineRouterLink %}.

  ![Details tab capital stack screenshot](https://assets.towerhunt.com/site/detailsTabCapitalStack0.png)

  Use this view to:

  - See which players are involved with a property right
  - Add new players to the capital stack
  - Trigger investments from existing capital stack layers

  ### Deal Hub

  The {% inlineRouterLink articleId="deal-hub" %}Deal Hub{% /inlineRouterLink %} tab displays a side-by-side "before" and "after" view of an asset's capital stack.

  ![Deal Hub capital stack screenshot](https://assets.towerhunt.com/site/dealHubCapitalStack0.png)

  The side-by-side capital stacks are particularly helpful for:

  - Seeing how a particular investment changed the capital stack
  - Filling in missing "links" in the {% inlineRouterLink articleId="the-role-of-investments" sectionName="Provenance" %}"chain of custody"{% /inlineRouterLink %} by using the {% inlineRouterLink articleId="timeline" %}timeline{% /inlineRouterLink %}
  - {% inlineRouterLink articleId="create-an-investment" %}Creating investments{% /inlineRouterLink %} that modify existing capital stacks

  ## Nested capital stacks

  Loans are investments, too. When you see a debt layer in the capital stack, that loan has *its own capital stack* in which the lender is an equity owner. Tower Hunt can track these nested capital stacks, providing visibility into the holdings of the players who operate in this ecosystem.

  ![Nested capital stack screenshot](https://assets.towerhunt.com/site/nestedCapitalStack0.png)`,
};
